import React from "react"
import "../wdyr"
import SEO from "../components/seo"
import { useQuery } from "react-query"

const AccessibilityPage = () => {
  const {
    data
  } = useQuery("accessibility", () =>
    fetch(`${process.env.API_URL}/${process.env.EVENT_ID}/staticpage/accessibility`).then(res =>
      res.json()
    )
  )
  
  return (
    <>
      <SEO title="Accessibility" />
      <div className="container-fluid">
        <div className="page">
          <h1 className="text-size-h3 font-primary-bold mb-35">Accessbility</h1>
          <div
              dangerouslySetInnerHTML={{
                __html: data?.result?.content,
              }}
            />
        </div>
      </div>
    </>
  )
}

export default AccessibilityPage
